// Gatsby supports TypeScript natively!
import React from "react"
import { PageProps, Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Layout from "../components/layout"
import SEO from "../components/seo"

import metaImage from "../images/logo_icon.png"

const SecondPage = (props: PageProps) => (
  <Layout>
    <SEO title="Mas sobre Dr. Mario Gutierrez" metaImage={metaImage}/>
    <div className="container section">
      <div className="content is-medium">
        <p className="subtitle is-size-5">
          <strong className="two-tones">Conoce más sobre mí...</strong>
        </p>
        <h1 className="title has-text-primary is-size-1 is-size-3-touch">
          Dr. Mario Gutierrez
        </h1>
        <p>
          El Dr. Gutiérrez es un Cirujano Oftalmólogo de Alta Especialidad en
          Retina y Segmento Anterior, especializado en el diagnóstico,
          tratamiento médico y quirúrgico en enfermedades del vítreo, retina y
          mácula, también ofrece atención a padecimientos como trauma ocular,
          catarata, cirugía refractiva láser, miopía y astigmatismo.
        </p>
        <p>
          Los avances tecnológicos y técnicas quirúrgicas mejoran
          constantemente, el Dr. Gutiérrez asiste continuamente a Congresos
          Internacionales de alta especialidad en oftalmología a nivel mundial
          como participante y profesor invitado.
        </p>
        <p>
          Cirugía de catarata avanzada, el Dr. Gutiérrez elimina lente natural
          (cristalino) opaco (catarata) a través de una microincisión de 2 mm y
          lo reemplaza con lentes intraoculares (IOL) de alta tecnología con
          productos mundialmente reconocidos como ALCON; de esta manera aquellos
          pacientes que sufren de hipermetropía, astigmatismo, miopía y/o
          presbicia pueden reducir y/o eliminar la dependencia del uso de gafas
          para actividades de visión cercana e intermedia (celular, computadora,
          tablet, pintura, ect) y actividades de visión lejana (manejar, jugar
          golf, cine, deportes, etc).
        </p>
        <p>
          Cirugía de retina mínimamente invasiva, la retina es una capa delgada
          de células en la parte posterior del ojo que detecta la luz y envía
          impulsos al cerebro a través del nervio óptico, lo que nos permite
          ver. La mácula se encuentra en el centro de la retina y ayuda a ver
          las cosas con todo detalle. Cuando cualquiera de estas áreas se ve
          afectada por una enfermedad, lesión o edad, puede producirse visión
          borrosa central, mancha en el centro de la visión o distorsión de las
          imágenes (metamorfopsias). La metamorfopsia hará que los pacientes
          vean líneas o flotadores deformados y puntos finos alrededor del borde
          o visión periférica. Es un defecto visual que hace que los objetos
          lineales, como las líneas en una cuadrícula, se vean curvas o
          redondeadas.
        </p>
        <p>
          Agujero macular, membrana epiretiniana, el Dr. Gutiérrez es cirujano
          experto en cirugía macular, utilizando técnicas avanzadas como flap de
          membrana limitante interna para el tratamiento de agujero macular
          grande. Se realiza cirugía de vitrectomia mínimante invasiva para la
          rehabilitación y recuperación de visión central; se trata de una
          cirugía de urgencia ya que el éxito anatómico y funcional estarán
          relacionados a la visión durante el diagnóstico, tamaño de agujero
          macular y tiempo de evolución. Degeneración macular relacionada con la
          edad (DMAE), es una enfermedad degenerativa ocular que afecta a
          personas mayores de 60 años, provocando alteraciones visuales al
          afectar la mácula, y por lo tanto, al centro de nuestro campo visual.
          El tabaquismo, la edad avanzada, antecedentes familiares de
          degeneración macular son considerados los principales factores de
          riesgo. Existen dos tipos de DMAE:
        </p>
        <ul>
          <li>
            La degeneración macular asociada a la edad(DMAE) seca ó atrofica:
            Afecta al 80% de los pacientes y se caracteriza por una evolución
            lenta y progresiva. Los depósitos que se acumulan en la zona van
            atrofiando la mácula y hacen que el paciente pierda lentamente
            visión en la zona central de su campo visual.
          </li>
          <li>
            La degeneración macular asociada a la edad(DMAE) húmeda ó exudativa:
            Se caracteriza por el crecimiento de nuevos vasos anormales y muy
            frágiles bajo la mácula. Estos vasos dejan escapar sangre y líquido.
            Se requiere tratamiento oportuno y por medio de inyecciones
            intravitreas con fármacos anti-angiogenicos para prevenir secuelas y
            conservar la visión a largo plazo.
          </li>
        </ul>
        <p>
          El desprendimiento de retina, es una separación de la retina por
          líquido sub-retiniano. El desprendimiento de la retina regmatógeno es
          el más frecuente y ocurre asociado a un desgarro o rotura de la
          retina, que permite que el vítreo licuado (degenerado) pase el espacio
          sub-retiniano. Una retina desprendida afecta las células nerviosas,
          que pueden dañarse permanentemente, y debe repararse rápidamente para
          evitar la pérdida de la visión. Cuando ocurre la rotura, los pacientes
          pueden ver manchas o flotadores que se mueven espontáneamente,
          destellos de luz o lo que parece ser una cortina oscura que envuelve
          su campo visual. Es importante acudir de inmediato con un especialista
          en retina para descartar un desprendimiento de retina. La valoración
          oftalmológica se convierte en una urgencia cuando: Las moscas volantes
          aparecen por primera vez o aparece una nueva. Repentinamente se ven
          centelleos o flash de luz en ausencia de estímulo luminoso. Cuando se
          ha presentado un traumatismo ocular reciente o antiguo. Cuando se
          tiene miopía, pues los agujeros y desgarros de retina son mucho más
          frecuentes en los pacientes con miopía. La retinopatía diabética, es
          una complicación de la diabetes que afecta los ojos al dañar los vasos
          sanguíneos del tejido sensible a la luz en la retina en la parte
          posterior del ojo. Si usted tiene diabetes, puede que al principio no
          notará ningún cambio en su visión. Pero con el tiempo, la retinopatía
          diabéticapuede causar una pérdida en la visión irreversible y es una
          de las principales causas de ceguera irreversible a nivel mundial.La
          condición puede desarrollarse en cualquier persona que tenga diabetes
          tipo 1 o tipo 2. La progresión de la retinopatía diabética estará
          relacionado al control de azúcar en sangre, hipertensión arterial y
          triglicéridos. Si tiene Diabetes, debe visitar al Dr. Gutiérrez para
          hacerse un examen de la vista, incluso si su visión es correcta.
          Acérquese a la Clínica Retina Consultores en la Ciudad de Guatemala de
          inmediato si su visión cambia repentinamente o se vuelve borrosa,
          manchada o con problemas de enfoque.
        </p>
        <p>
          Cirugía refractiva, es el conjunto de técnicas quirúrgicas que tiene
          como finalidad de eliminar o minimizar los defectos refractivos como
          miopía, hipermetropía, astigmatismo y/o presbicia. Se puede realizar a
          través de técnicas láser ó a través de un implante de lente
          intraocular fáquica tipo Visian ICL STAAR Surgical.
        </p>
        <p>
          El objetivo principal del Dr. Mario Gutiérrez, y el equipo médico del
          Centro de Retina y Macula Consultores de Guatemala es ofrecer a los
          pacientes la mejor atención personal y tecnología científica para
          cuidar su visión y salud ocular. Brindan las mejores alternativas en
          el campo de la prevención, diagnóstico, tratamiento y rehabilitación
          en enfermedades del ojo.
        </p>
      </div>
      <div className="content">
        <Link
          className="button is-medium is-rounded is-bg-gradient"
          to="/#aboutSection"
        >
          <span className="icon">
            <FontAwesomeIcon icon="chevron-circle-left" />
          </span>
          <span>Volver a inicio</span>
        </Link>
      </div>
    </div>
  </Layout>
)

export default SecondPage
